body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.adm-form-item.adm-form-item-horizontal.adm-list-item {
  border-radius: 0.5rem;
}

.adm-list-item-content {
  margin-bottom: 0;
  border: none
}

.adm-list-item-content .adm-list-item-content-main {
  padding: 0;
}

.adm-input>.adm-input-element,
.adm-form-item-label {
  font-size: 1rem;
}

.adm-form .adm-list-item {
  background: #f7f8f9;
  padding-left: 0;
}

.adm-input .adm-input-element {
  padding: 0.5rem;
  background: #fff;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  box-shadow: 0px 0px 15px #eef1f4;
}

.adm-form .adm-form-item.adm-form-item-vertical .adm-form-item-label {
  font-size: 1rem;
}

[disabled] {
  color: #999;
}

/* .computer .adm-card{
  background: #f7f8f9;
  border: 1px solid #d6e2ec;
} */
.computer {
  padding: 12px;
  background: #f7f8f9;
  min-height: 100vh;
}

.adm-list adm-list-card {
  padding: 0;
  margin: 1rem 0;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper tfoot>tr>th,
:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper tfoot>tr>td {
  padding: 1rem .1rem;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper .ant-table-thead>tr>td {
  background-color: #fff;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper .ant-table {
  font-size: .8rem;
}

.adm-list-item .adm-list-item-content {
  border-top: 0;
  overflow-x: auto;
}

.adm-card .adm-card-header {
  justify-content: center;
}

#root .adm-form>.adm-form-footer {
  padding: 0 12px
}

tr.ant-table-row:last-child td {
  border-bottom: none;
}

.adm-card {
  width: calc(100% - 48px);
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper {
  width: 100%;
}

.adm-collapse-panel-header .adm-list-item-content-main {
  padding: 12px 0;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}
.adm-collapse{
  margin-bottom: 1rem;
  width: calc(100% - 24px);
}
.adm-list-body{
  border-radius: 0.5rem;
}
.adm-list-body .adm-collapse-panel-content{
  color: #333;
}