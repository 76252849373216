.extra {
  /* font-weight: 500; */
  color: #333;
}

.footer {
  margin: 24px 12px;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: .5rem;
  font-weight: bold
}

.qrcode {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: .5rem;
  font-weight: bold;
  width: calc(100%);
}

.footer span {
  display: inline-block;
  line-height: 1.5rem;
  color: #555;
  font-size: .9rem;
}

.result {
  margin-bottom: 1rem;
}

.result-pannel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.result-pannel>button {
  margin-top: 24px;
  width: calc(100% - 24px);
}
.param-pannel{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap:6px;
  padding-bottom: 1rem
}
.label{
  color:#999;
  font-size:.8rem
}
.param{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tag-list>span{
  font-size: .8rem;
  padding: 0.2rem 0.3rem;
  border-radius: 0.2rem;
  margin-right: 0.3rem;
  background: #e7edff;
  color: #587fff;
}
.tag-list{
  background: #f7f8f9;
  padding-bottom: .5rem;
}